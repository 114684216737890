<template>
  <form @submit.prevent="submit">
    <div class="row">
      <loading-notification :show="loading" />
    </div>
    <div class="row">
      <div class="flex xs12 md4">
        <va-input
          color="info"
          v-model="form.name.value"
          :disabled="loading"
          :label="$t('layout.form.nameInput')"
          :error="!!form.name.errors.length"
          :error-messages="translatedErrors(form.name.errors)"
          @blur="validate('name')"
        />
      </div>
      <div class="flex xs12 md4">
        <va-select
          v-model="selectedParent"
          :label="$t('groups.inputs.parentInput')"
          :options="parentsList"
          :loading="isLoading.parents"
          :no-options-text="$t('layout.empty')"
          :disabled="loading"
          text-by="name"
          searchable
        />
      </div>
      <div class="flex xs12 md4">
        <va-checkbox
          :label="$t('groups.inputs.superInput')"
          :disabled="loading"
          v-model="group.super"
        />
      </div>
    </div>

    <div
      class="permissions"
      v-if="!isNew"
    >
      <legend>{{ $t('groups.permissions') }}</legend>
      <i class="bar"></i>
      <div class="row">
        <div
          class="flex xs12 sm6 md4"
          v-for="permission in permissions"
          :key="permission.label"
        >
          <permission-view
            :editable="!loading"
            :label="permission.label"
            :category="permission.acos"
          ></permission-view>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="flex xs12">
        <va-button
          color="primary"
          :disabled="loading"
        >
          <text-loading
            :loading="loading"
            icon="fa fa-save"
          >
            {{ $t('layout.form.save') }}
          </text-loading>
        </va-button>
      </div>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'
import { validatorMixin } from '@/services/validator'
const PermissionView = () => import(/* webpackPrefetch: true */ './TreeView')

export default {
  name: 'groups-form',
  components: {
    PermissionView,
  },
  props: {
    loading: {
      type: Boolean,
      default: function () { return false },
    },
    group: {
      type: Object,
      required: false,
      default: function () { return { super: false } },
    },
  },
  mixins: [validatorMixin],
  computed: {
    ...mapGetters(['currentUser', 'currentLocale']),
    isNew () {
      return this.group.id === 0
    },
  },
  data () {
    return {
      permissions: [],
      parentsList: [],
      selectedParent: '',
      isLoading: {
        parents: false,
      },
      isError: {
        parents: false,
      },
      form: {
        name: {
          value: '',
          validate: {
            required: true,
          },
          errors: [],
        },
      },
    }
  },
  watch: {
    group (val) {
      this.setGroup(val)
    },
    currentLocale (val) {
      this.validateAll()
    },
    'currentUser.permissions' (val) {
      this.initialData()
    },
  },
  created () {
    this.initialData()
    this.$nextTick(() => {
      this.validateAll()
    })
  },
  methods: {
    async setGroup (val) {
      this.setFormData(val)
      if (val.acos) {
        this.updatePermissions(val.acos)
      }

      if (val.parent) {
        this.selectedParent = val.parent
      }

      if (val.id) {
        await this.$nextTick()
        this.validateAll()
      }
    },
    initialData () {
      if (this.group) {
        this.setGroup(this.group)
      }

      if (this.parentsList.length === 0) {
        this.getGroups()
      }
    },
    updatePermissions (acos) {
      this.permissions = []

      Object.keys(acos).forEach(k => {
        const permission = {
          label: k,
          acos: acos[k],
        }

        this.permissions.push(permission)
      })
    },
    async submit () {
      this.validateAll()
      if (!this.formReady) return

      const group = this.getFormData(this.group)
      group.acos = {}
      this.permissions.forEach(p => {
        group.acos[p.label] = p.acos
      })
      if (this.selectedParent) {
        group.parent_id = this.selectedParent.id
      }
      delete group.parent

      this.$emit('submit', group)
    },
    async getGroups () {
      if (!this.currentUser.can('Groups', 'index')) return

      this.isLoading.parents = true
      let response = false
      let route = 'groups'
      if (this.group.id) {
        route += ('?not=' + this.group.id.toString())
      }
      try {
        response = await this.$http.get(route)
      } catch (e) {
        // console.log('Cant fetch groups', e)
        this.isLoading.parents = false
        return
      }

      this.isLoading.parents = false
      this.parentsList = response.data.data
      this.parentsList.forEach(w => {
        w.name = this.$t('groups.' + w.name)
      })
    },
  },
}
</script>
<style scoped>
.permissions {
  margin-bottom: 25px;
}
</style>
